@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
:root {
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif; }

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  font-family: inherit; }

a {
  text-decoration: none;
  color: black; }

ul {
  list-style: none; }

a,
h4,
h3 label {
  text-transform: capitalize; }

span {
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #333333; }

button {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 0.5em 1em;
  text-transform: capitalize;
  font-weight: 500;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

label {
  text-transform: capitalize; }

div {
  width: 100%; }

textarea {
  display: block; }

input[type="text"] {
  width: 100%; }

.normal {
  background: #e0e0e0;
  box-shadow: 0px 2px 3px rgba(51, 51, 51, 0.2); }
  .normal.focus:hover {
    background-color: #aeaeae; }

.outline {
  border: 1px solid #3d5afe;
  color: #3d5afe; }
  .outline.focus:hover {
    background-color: rgba(41, 98, 255, 0.1); }

.text {
  background-color: transparent; }
  .text.focus:hover {
    color: #3d5afe; }

.shadow {
  background: #3d5afe;
  color: white; }

.default {
  color: #9e9e9e;
  background-color: #e0e0e0; }

.desabilitado {
  color: #9e9e9e;
  background-color: transparent; }

.second {
  background: #455a64;
  box-shadow: 0px 2px 3px rgba(69, 90, 100, 0.2);
  color: white; }
  .second.focus:hover {
    background: #1c313a; }

.danger {
  background: #d32f2f;
  box-shadow: 0px 2px 3px rgba(211, 47, 47, 0.2);
  color: white; }
  .danger.focus:hover {
    background: #9a0007; }

.primary.focus:hover {
  background: #0039cb; }

.base {
  width: 73px; }

.medium {
  width: 81px; }

.largo {
  width: 100px; }

.fila {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.derecha {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center; }

.izquierda {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.foco {
  font-weight: bolder;
  font-size: 1.2rem; }

.rojo {
  font-size: inherit;
  color: #f7542e;
  display: inline-block; }

input[type="text"], textarea {
  border-radius: 8px;
  background-color: white;
  border: 1px solid #828282;
  box-shadow: none;
  padding: 0.5em 1em;
  outline: none; }
  input[type="text"]:focus, textarea:focus {
    border: none;
    outline: 1px solid #3485f0; }

input[type="text"].sin_estilo {
  border: none; }

input[type="text"].normal {
  border: 1px solid #828282; }

input[type="text"].hover {
  border: 1px solid #333333; }

input[type="text"].focus {
  border: 1px solid #2962ff; }

input[type="text"].error {
  border: 1px solid #d32f2f; }

input[type="text"].default {
  border: 1px solid #e0e0e0;
  background: #f2f2f2; }

.text_error {
  color: red; }

#root {
  width: 100%; }
  #root * + * {
    margin-top: 1.5rem; }
  #root .encabezado {
    width: 100%;
    padding: 0.5em 1em;
    background-color: #fafbfd; }
    #root .encabezado .titulo_encabezado {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start; }
    #root .encabezado .barra_lateral ul {
      width: 100%;
      height: 15vh;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start; }
      #root .encabezado .barra_lateral ul > li + li {
        margin-top: 0; }
      #root .encabezado .barra_lateral ul li {
        flex-basis: 33%; }
        #root .encabezado .barra_lateral ul li a {
          padding: 0.5rem;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
    @media screen and (min-width: 50rem) {
      #root .encabezado {
        min-height: 100vh; }
        #root .encabezado .barra_lateral ul {
          display: block; }
          #root .encabezado .barra_lateral ul > li + li {
            margin-top: 2rem; } }
  #root .contenido {
    width: 100%; }
    #root .contenido #botones {
      width: 100%;
      padding: 0.5rem 1.5rem; }
      #root .contenido #botones .fila {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; }
        #root .contenido #botones .fila section,
        #root .contenido #botones .fila > div {
          margin-top: 0; }
        #root .contenido #botones .fila > section,
        #root .contenido #botones .fila > div {
          flex-basis: 45%; }
        #root .contenido #botones .fila .info_boton {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
      #root .contenido #botones .basico button.end {
        flex-direction: row-reverse; }
      #root .contenido #botones .basico button span {
        color: white; }
      #root .contenido #botones .basico button p {
        margin-top: 0; }
      #root .contenido #botones .basico .cuatro {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        align-items: center; }
        #root .contenido #botones .basico .cuatro .info_boton {
          flex-basis: 20%; }
      #root .contenido #botones .configuracion {
        display: block; }
        #root .contenido #botones .configuracion .estado {
          flex-basis: 40%;
          padding: 0.5em;
          box-shadow: 0px -1px 5px 6px rgba(90, 90, 90, 0.126);
          border: none;
          border-radius: 12px; }
          #root .contenido #botones .configuracion .estado .fila {
            justify-content: flex-start;
            align-items: center; }
            #root .contenido #botones .configuracion .estado .fila label,
            #root .contenido #botones .configuracion .estado .fila input {
              margin-top: 0; }
            #root .contenido #botones .configuracion .estado .fila input {
              margin-right: 1rem;
              margin-left: 0.25rem; }
        @media screen and (min-width: 50rem) {
          #root .contenido #botones .configuracion {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center; }
            #root .contenido #botones .configuracion div {
              margin-top: 0; } }
      @media screen and (min-width: 37.5rem) {
        #root .contenido #botones .fila.cuatro {
          flex-wrap: nowrap;
          justify-content: space-between; } }
    #root .contenido #input {
      width: 100%;
      padding: 0.5rem 1.5rem; }
      #root .contenido #input .info_boton {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        #root .contenido #input .info_boton * + * {
          margin-top: 0.5rem; }
        #root .contenido #input .info_boton .input {
          border-radius: 8px;
          background-color: white;
          border: 1px solid #828282;
          box-shadow: none;
          padding: 0.5em 1em;
          outline: none; }
          #root .contenido #input .info_boton .input input[type="text"] {
            padding: 0; }
          #root .contenido #input .info_boton .input * + * {
            margin-top: 0; }
      @media screen and (min-width: 600px) {
        #root .contenido #input .basico {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          #root .contenido #input .basico .info_boton {
            margin-top: 0;
            margin-left: calc(10% / 2);
            flex-basis: 30%; }
          #root .contenido #input .basico.fullwidt .info_boton {
            flex-basis: 100%; } }
    #root .contenido .pie_pagina {
      padding: 2em;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      #root .contenido .pie_pagina span {
        font-size: 1rem; }
        #root .contenido .pie_pagina span a {
          color: #333333; }
  @media screen and (min-width: 50rem) {
    #root {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch; }
      #root .encabezado {
        flex-basis: 25%; }
      #root #buttons {
        flex-basis: 75%; } }
